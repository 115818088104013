import React from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import {TabTitle} from "../../utils/GeneralFunctions";
import mwfull from "../../assests/mwfull.JPG";

const Models = () => {
  TabTitle('Models Wanted - KamaStudio');

  return (
    <>
      <Navbar />
      {/* heading block */}
      <div className="services-header absolute-centre">Models Wanted</div>
      <div className="models-container max-width">
        <section class="text-gray-600 body-font">
          <div class="container px-5 py-24 mx-auto flex flex-wrap">
            <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
              <img
                alt="feature"
                class="object-cover object-center h-full w-full"
                src={mwfull}
                data-aos="fade-down"
                data-aos-duration="2000"
              />
            </div>
            <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
              <div class="flex flex-col mb-10 lg:items-start items-center absolute-centre">
                <div class="w-12 h-12 inline-flex items-center justify-center  rounded-full bg-indigo-100 text-indigo-500 mb-5 "style={{backgroundColor:`#A75D5D`}}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"style={{color:`#FFF3EC`}}></path>
                  </svg>
                </div>
                <div class="flex-grow">
                  <h2 class=" Faq-h2 text-gray-900 text-lg title-font font-medium mb-3"style={{color:`#A75D5D`}}>
                    Sensitivity Test
                  </h2>
                  <p class="leading-relaxed text-base text-black">
                  New clients or those without a color appointment in six months must take a 48-hour skin test prior to coloring. Call to book; walk-ins subject to availability. Safety first!
                  </p>
                </div>
              </div>
              <div class="flex flex-col mb-10 lg:items-start items-center absolute-centre">
                <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5"style={{backgroundColor:`#A75D5D`}}>
                <svg class="h-6 w-6 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"style={{color:`#FFF3EC`}}/>  <path d="M18 6h-11h3a4 4 0 0 1 0 8h-3l6 6" style={{color:`#FFF3EC`}}/>  <line x1="7" y1="10" x2="18" y2="10" style={{color:`#FFF3EC`}}/></svg>
                </div>
                <div class="flex-grow">
                  <h2 class="Faq-h2 text-gray-900 text-lg title-font font-medium mb-3"style={{color:`#A75D5D`}}>
                    Charges
                  </h2>
                  <p class="leading-relaxed text-base text-black">
                  Haircuts and color services for hair below the shoulders or with extensive roots are subject to an additional charge.
                  </p>
                </div>
              </div>
              <div class="flex flex-col mb-10 lg:items-start items-center absolute-centre">
                <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5"style={{backgroundColor:`#A75D5D`}}>
                <svg class="h-6 w-6 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"style={{color:`#FFF3EC`}}/>  <line x1="18" y1="6" x2="6" y2="18" style={{color:`#FFF3EC`}}/>  <line x1="6" y1="6" x2="18" y2="18" style={{color:`#FFF3EC`}}/></svg>
                </div>
                <div class="flex-grow">
                  <h2 class="Faq-h2 text-gray-900 text-lg title-font font-medium mb-3"style={{color:`#A75D5D`}}>
                    Cancellation
                  </h2>
                  <p class="leading-relaxed text-base text-black">
                  We kindly request a 24-hour notice for cancellations. If you're running a bit late, please understand that we may need to reschedule your appointment. Thank you for your understanding!  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Models;
