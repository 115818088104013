import React from "react";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./home.css";
import cover1 from "../../assests/cover1.JPG";
import cover2 from "../../assests/cover2.JPG";
import cover3 from "../../assests/cover3.JPG";
import cover4 from "../../assests/cover4.JPG";
import w1 from "../../assests/w1.JPG";
import w258 from "../../assests/w258.JPG";
import w2 from "../../assests/w2.JPG";
import styling from "../../assests/styling.JPG";
import nb2a from "../../assests/nb2a.JPG";
import haircut from "../../assests/haircut.JPG";
import colors from "../../assests/colors.jpg";
import w3 from "../../assests/w3.JPG";
import w4 from "../../assests/w4.JPG";
import w5 from "../../assests/w5.JPG";
import w6 from "../../assests/w6.JPG";
import w7 from "../../assests/w7.JPG";
import w8 from "../../assests/w8.JPG";
import w9 from "../../assests/w9.JPG";
import w10 from "../../assests/w10.JPG";
import w11 from "../../assests/w11.JPG";
import cnss from "../../assests/cnss.JPG";
import coursesimg from "../../assests/coursesimg.JPG";
import mw from "../../assests/mw.JPG";
import n from "../../assests/n.JPG";
import gf from "../../assests/gf.JPG";
import mm from "../../assests/mm.JPG";
import nails from "../../images/nails.jpeg";
import spa from "../../images/spa.jpeg";
import hair from "../../images/hair.jpeg";
import makeup from "../../images/makeup.jpeg";
import BearDburys from "../../images/BearDburys-logo.png";
import Cheryl from "../../images/Cheryl-s-Cosmeceuticals-Logo-modified.png";
import ikonic from "../../images/ikonic-logo.png";
import Loreal from "../../images/Logo-Loreal-Professional-Paris.png";
import marc from "../../images/marc-logo.png";
import nashi from "../../images/nashi-argan-logo.png";
import O3 from "../../images/O3-logo.png";
import makeupstudio from "../../images/make-up-studio-logo.png";
import qod from "../../images/qod-logo.png";
import schwa from "../../images/Schwarzkopf_Professional-logo.png";
import rika from "../../images/rica-logo.png";
import shea from "../../images/shea-logo.png";
import skeyndor from "../../images/skeyndor-logo.png";
import vedic from "../../images/vedic-valley-logo.png";
import wahl from "../../images/wahl-pro-logo.png";
import wella from "../../images/wella-logo.png";
import { TabTitle } from "../../utils/GeneralFunctions";
import { Link } from "react-router-dom";

const Home = () => {
  TabTitle("Home - KamaStudio");
  return (
    <>
      <Navbar />

      <div className="home-container ">

        {/* image header slider */}
        <div
          id="carouselExampleInterval"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <img
                src={cover1}
                class=" img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={cover2}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={cover3}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={cover4}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={cnss}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={nb2a}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img
                src={w258}
                class="img-responsive d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        {/* <div className="image-slider">
        </div> */}

        {/* catchy lines */}
        <div className="lines-home max-width">
          <section class="bg-white dark:bg-gray-900">
            <div class="py-2 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
              <div class="mx-auto max-w-screen-sm text-center">
                <h2 class=" about-h1 mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 ">
                  Experience beauty redefined
                </h2>
                <p class="mb-6  text-black dark:text-gray-400 md:text-lg">
                  Experience exceptional salon services and ignite your
                  potential with our comprehensive training programs. Embrace
                  your beauty journey at Kama Studio.
                </p>
                <button
                  type="button"
                  class=" max-width home-button1 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  {" "}
                  <a
                    className="appoinment-call-btn"
                    href="tel:0712-4069279"
                    style={{
                      display: `inline-flex`,
                      textDecorationLine: `none`,
                      alignItems: `center`,
                    }}
                  >
                    <svg
                      class="h-6 w-6 mr-2 text-black"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        style={{ color: `#FFF3EC` }}
                      />{" "}
                      <path
                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
                        style={{ color: `#FFF3EC` }}
                      />{" "}
                      <path
                        d="M15 7a2 2 0 0 1 2 2"
                        style={{ color: `#FFF3EC` }}
                      />{" "}
                      <path
                        d="M15 3a6 6 0 0 1 6 6"
                        style={{ color: `#FFF3EC` }}
                      />
                    </svg>
                    Book an Appointment
                  </a>
                </button>
              </div>
            </div>
          </section>
        </div>

        {/* about-us-home */}
        <div className="home-about" style={{ backgroundColor: `#FFF3EC` }}>
          <div class="container">
            <section class="mb-32 md:px-8  max-width">
              <div class="mb-16 flex flex-wrap max-width ">
                <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-15 ">
                  <div
                    class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    <img
                      src="https://media.istockphoto.com/id/1325465927/photo/asian-student-studying-e-learning-online-education-concept-portrait-of-handsome-indian.webp?b=1&s=170667a&w=0&k=20&c=LNpUPG4s7rH68tTXNqEsrngv5_wjETMdWWvhMwzPHhU="
                      class="w-full "
                      alt="Louvre"
                    />
                  </div>
                </div>

                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-40 ">
                  <h1 class=" about-h1 mb-4 text-2xl font-bold text-right">
                    Step into a world of beauty and transformation
                  </h1>

                  <p class="mb-6 text-black dark:text-neutral-300 text-justify">
                    Our team of skilled and passionate professionals is
                    dedicated to providing exceptional services, using the
                    latest techniques and top-quality products. Whether you seek
                    a fresh new hairstyle, a revitalizing facial, or a perfect
                    manicure, we tailor each experience to cater to your
                    individual needs and desires.
                  </p>
                  <Link to={"/about"}>
                    <button
                      type="button"
                      class=" home-button inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* services section */}
        <h1
          className="about-h1 absolute-centre max-width"
          style={{ marginTop: `50px`, paddingTop: `10px` }}
        >
          Services We Offer
        </h1>
        <div className="bg-white py-6 sm:py-8 lg:py-12 max-width ">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8 absolute-centre  ">
            <div className="mb-6 flex items-end  gap-4"></div>

            <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4 ">
              <div>
                <Link className="group mb-2 block h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                  <img
                    src={n}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>
              </div>

              <div>
                <Link className="group mb-2 block h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                  <img
                    src={haircut}
                    loading="lazy"
                    alt="Photo by engin akyurt"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>
              </div>

              <div>
                <Link className="group mb-2 block h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3">
                  <img
                    src={styling}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>
              </div>

              <div>
                <Link className="group mb-2 block h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3 absolute-centre">
                  <img
                    src={colors}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>
              </div>
            </div>
          </div>
          <Link to={"/services"}>
            <button
              type="button"
              class=" max-width home-button1 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              View More
            </button>
          </Link>
        </div>

        {/* our brand section */}
        <div className="brand-logo-container">
          <h2 className="brand-heading absolute-centre">Our Brand Partners</h2>
          <div className="brand-logos">
            <div className="brand-logos-slide ">
              <img src={BearDburys} alt="logo" />
              <img src={makeupstudio} alt="logo" />
              <img src={rika} alt="logo" />
              <img src={Cheryl} alt="logo" />
              <img src={Loreal} alt="logo" />
              <img src={wella} alt="logo" />
              <img src={vedic} alt="logo" />
              <img src={qod} alt="logo" />
              <img src={nashi} alt="logo" />
              <img src={skeyndor} alt="logo" />
              <img src={ikonic} alt="logo" />
              <img src={wahl} alt="logo" />
              <img src={shea} alt="logo" />
              <img src={O3} alt="logo" />
              <img src={marc} alt="logo" />
              <img src={schwa} alt="logo" />
              <img src={BearDburys} alt="logo" />
              <img src={makeupstudio} alt="logo" />
              <img src={rika} alt="logo" />
              <img src={Cheryl} alt="logo" />
              <img src={Loreal} alt="logo" />
              <img src={wella} alt="logo" />
              <img src={vedic} alt="logo" />
              <img src={qod} alt="logo" />
              <img src={nashi} alt="logo" />
              <img src={skeyndor} alt="logo" />
              <img src={ikonic} alt="logo" />
              <img src={wahl} alt="logo" />
              <img src={shea} alt="logo" />
              <img src={O3} alt="logo" />
              <img src={marc} alt="logo" />
              <img src={schwa} alt="logo" />
              <img src={BearDburys} alt="logo" />
              <img src={makeupstudio} alt="logo" />
              <img src={rika} alt="logo" />
              <img src={Cheryl} alt="logo" />
              <img src={Loreal} alt="logo" />
              <img src={wella} alt="logo" />
              <img src={vedic} alt="logo" />
              <img src={qod} alt="logo" />
              <img src={nashi} alt="logo" />
              <img src={skeyndor} alt="logo" />
              <img src={ikonic} alt="logo" />
              <img src={wahl} alt="logo" />
              <img src={shea} alt="logo" />
              <img src={O3} alt="logo" />
              <img src={marc} alt="logo" />
              <img src={schwa} alt="logo" />
            </div>
          </div>
        </div>

        {/* Hero section */}
        <div className="hero-section max-width">
          <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="mb-6 flex items-end justify-between gap-4"></div>

              <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
                <div>
                  <Link
                    to={"/courses"}
                    className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  >
                    <img
                      src={coursesimg}
                      loading="lazy"
                      alt="Photo by Austin Wade"
                      className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </Link>

                  <div className="Faq-h2 flex flex-col">
                    <a
                      href="#"
                      className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                    >
                      Courses
                    </a>
                  </div>
                </div>

                <div>
                  <Link
                    to={"/models-wanted"}
                    className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  >
                    <img
                      src={mw}
                      loading="lazy"
                      alt="Photo by engin akyurt"
                      className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </Link>

                  <div className="Faq-h2 flex flex-col">
                    <Link
                      to={"/models-wanted"}
                      className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                    >
                      Models Wanted
                    </Link>
                  </div>
                </div>

                <div>
                  <Link
                    to={"/giftcard-membership"}
                    className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  >
                    <img
                      src={gf}
                      loading="lazy"
                      alt="Photo by Austin Wade"
                      className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </Link>

                  <div className="Faq-h2 flex flex-col">
                    <Link
                      to={"/giftcard-membership"}
                      className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                    >
                      Gift Card
                    </Link>
                  </div>
                </div>

                <div>
                  <Link
                    to={"/giftcard-membership"}
                    className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3 absolute-centre"
                  >
                    <img
                      src={mm}
                      loading="lazy"
                      alt="Photo by Austin Wade"
                      className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </Link>

                  <div className="Faq-h2 flex flex-col ">
                    <a
                      href="#"
                      className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre "
                    >
                      Membership
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="meet-container absolute-centre">
          <h2 class="about-h1 pb-20 py-20 text-3xl font-bold">Our Work</h2>
        </div>
        <div className="work absolute-centre max-width">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w1}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w2}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w3}
                  alt=""
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w4}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w5}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w6}
                  alt=""
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w11}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w7}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w8}
                  alt=""
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w9}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={w10}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={cnss}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
