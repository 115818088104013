import React from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./contact.css";

import video from "../../assests/demo-video.mp4";
import { MdCall } from "react-icons/md";
import { RiScissorsFill } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import {TabTitle} from "../../utils/GeneralFunctions";


const Contact = () => {
  TabTitle('Contact Us - KamaStudio');
  return (
    <>
      <Navbar />
      {/* heading-container */}
      <div className="services-header absolute-centre">Contact Us</div>
      {/* contact-container */}
      <div className="contact-container ">
        {/* contact-content */}
        <section class="text-gray-600 body-font max-width">
          <div class="container px-5 py-24 mx-auto">
            <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div
                  class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"
                  style={{ backgroundColor: `#A75D5D` }}
                >
                  <svg
                    class="h-6 w-6 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <rect
                      x="7"
                      y="4"
                      width="10"
                      height="16"
                      rx="1"
                      style={{ color: `#FFF3EC` }}
                    />{" "}
                    <line
                      x1="11"
                      y1="5"
                      x2="13"
                      y2="5"
                      style={{ color: `#FFF3EC` }}
                    />{" "}
                    <line
                      x1="12"
                      y1="17"
                      x2="12"
                      y2="17.01"
                      style={{ color: `#FFF3EC` }}
                    />
                  </svg>
                </div>
                <div class="flex-grow">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                  Contact
                  </h2>
                  <p class=" text-base">
                  Unlock the magic of beauty at Kama Studio! Call us on <span style={{ color: `#A75D5D` }}>0712-4069279</span> or 
                  message us on WhatsApp <span style={{ color: `#A75D5D` }}>860012358</span>
                  </p>
                </div>
              </div>
              <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"style={{ backgroundColor: `#A75D5D` }}>
                <svg
                    class="h-6 w-6 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      style={{ color: `#FFF3EC` }}
                    />{" "}
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      style={{ color: `#FFF3EC` }}
                    />{" "}
                    <polyline
                      points="12 7 12 12 15 15"
                      style={{ color: `#FFF3EC` }}
                    />
                  </svg>
                </div>
                <div class="flex-grow">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                    Timings
                  </h2>
                  <p class=" text-base">
                  Step into a week-long oasis of beauty and charm, as our doors swing open from <span style={{ color: `#A75D5D` }}>Monday to Sunday, 9 AM to 9 PM</span> 
                  
                  </p>
                </div>
              </div>
              <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"style={{ backgroundColor: `#A75D5D` }}>
                <svg
                    class="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      style={{ color: `#FFF3EC` }}
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      style={{ color: `#FFF3EC` }}
                    />
                  </svg>
                </div>
                <div class="flex-grow">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                    Address
                  </h2>
                  <p class="text-base">
                  274/2, Central Bazar Road, below sakal media pvt ltd, New Ramdaspeth, Nagpur, Maharashtra 440010
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        

        {/* video-container */}
        <div className="video-container max-width  ">
          <video autoplay loop muted controls>
            <source src={video} type="video/mp4" />
          </video>
        </div>

        <div className="map max-width">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.4941053973125!2d79.06755997498388!3d21.132725980542702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1493be23e3d%3A0x13fed6b9092eab71!2sKama%20studio!5e0!3m2!1sen!2sin!4v1689599041911!5m2!1sen!2sin"
            height="450"
          ></iframe>
        </div>
      </div>
           
  

      <Footer />
    </>
  );
};

export default Contact;
