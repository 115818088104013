import React from 'react'
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import './vip.css';
import {TabTitle} from "../../utils/GeneralFunctions";

const Vip = () => {
  TabTitle("Gift Card & VIP Membership - KamaStudio")
  return (
    <>
      <Navbar/>
       <div className="vip-container">
        {/* VIP BODY */}
        <div className="vip-header absolute-centre">Gift Card &  VIP Membership</div>

        {/* VIP MAIN BODY */}
        <div className="vip-body max-width">

        <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="text-center mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4" style={{color:`#A75D5D`, fontWeight:`600`}}>
              VIP Membership
            </h1>
        
            <div class="flex mt-6 justify-center" >
              <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex" style={{backgroundColor:`#A75D5D`}}></div>
            </div>
          </div>
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"  style={{backgroundColor:`#A75D5D`}}>
              <svg class="h-6 w-6 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="9" y1="15" x2="15" y2="9" style={{ color: `#FFF3EC` }} />  <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" style={{ color: `#FFF3EC` }}/>  <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" style={{ color: `#FFF3EC` }}/>  <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55 v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55 v-1" style={{ color: `#FFF3EC` }}/></svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Special Discounts
                </h2>
                <p class="leading-relaxed text-base text-black">
                Benefit from exclusive discounts on a wide range of services, products, and packages.
                </p>
             
              </div>
            </div>
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0" style={{backgroundColor:`#A75D5D`}}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                 
                >
                  <circle cx="6" cy="6" r="3"  style={{color:`#FFF3EC`}}></circle>
                  <circle cx="6" cy="18" r="3"  style={{color:`#FFF3EC`}}></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12" style={{color:`#FFF3EC`}} ></path>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Complimentary Upgrades
                </h2>
                <p class="leading-relaxed text-base text-black">
                Treat yourself to complimentary upgrades, such as deluxe treatments, personalized consultations, and more.
                </p>
              
              </div>
            </div>
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"  style={{backgroundColor:`#A75D5D`}}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" style={{color:`#FFF3EC`}}></path>
                  <circle cx="12" cy="7" r="4" style={{color:`#FFF3EC`}}></circle>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Personalized Service
                </h2>
                <p class="leading-relaxed text-base text-black">
                Experience tailored services that cater to your unique preferences and beauty goals.
                </p>
              
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="text-center mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4" style={{color:`#A75D5D`, fontWeight:`600`}}>
              Gift Cards
            </h1>
            <div class="flex mt-6 justify-center">
              <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex" style={{backgroundColor:`#A75D5D`}}></div>
            </div>
          </div>
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"  style={{backgroundColor:`#A75D5D`}}>
              <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" style={{color:`#FFF3EC`}}></path>
                  <circle cx="12" cy="7" r="4" style={{color:`#FFF3EC`}}></circle>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Personalized Touch
                </h2>
                <p class="leading-relaxed text-base text-black">
                Tailor your gift card to suit the recipient's preferences, allowing them to choose the services that resonate with them.
                </p>
               
              </div>
            </div>
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"  style={{backgroundColor:`#A75D5D`}}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3" style={{color:`#FFF3EC`}}></circle>
                  <circle cx="6" cy="18" r="3" style={{color:`#FFF3EC`}}></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12" style={{color:`#FFF3EC`}}></path>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Convenience
                </h2>
                <p class="leading-relaxed text-base text-black">
                Easily purchase of gift card with hassle-free and thoughtful gesture for birthdays, anniversaries, holidays, or just because.
                </p>
                
              </div>
            </div>
            <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0"  style={{backgroundColor:`#A75D5D`}}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" style={{color:`#FFF3EC`}}></path>
                  <circle cx="12" cy="7" r="4" style={{color:`#FFF3EC`}}></circle>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"style={{ color: `#A75D5D` }}>
                Luxurious Experience
                </h2>
                <p class="leading-relaxed text-base text-black">
                Your loved ones will step into a world of relaxation and beauty, enjoying top-tier services from our skilled professionals.
                </p>
                
              </div>
            </div>
          </div>
      
        </div>
      </section>

      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">

          <div class="mb-6 grid gap-6 sm:grid-cols-2 md:mb-8 lg:grid-cols-2 lg:gap-8">
            <div class="flex flex-col rounded-lg border p-4 pt-6">
              <div class="mb-12">
                <div class="mb-2 text-center text-2xl font-bold text-gray-800" style={{color:`#A75D5D`}} >
                  VIP Membership
                </div>

                <p class="mx-auto mb-8 px-8 text-center text-black" >
                Elevate Your Salon Experience
                </p>

                <div class="space-y-2 ">
                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Priority Access</span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">
                    Exclusive Discounts
                    </span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Personalized Care</span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"  style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Luxury and Savings</span>
                  </div>

                </div>
              </div>

              <div class="mt-auto flex flex-col gap-8">
                

                <a
                  href="#"
                  class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base"
                  style={{backgroundColor:`#A75D5D`}}>
                  Get Now!
                </a>
              </div>
            </div>

            <div class="flex flex-col rounded-lg border p-4 pt-6">
              <div class="mb-12">
                <div class="mb-2 text-center text-2xl font-bold text-gray-800" style={{color:`#A75D5D`}}>
                  Gift Cards
                </div>

                <p class="mx-auto mb-8 px-8 text-center text-black">
                The Perfect Gift Solution
                </p>

                <div class="space-y-2">
                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Easily purchase</span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">
                    Wide range of services available
                    </span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Ideal for birthdays, anniversaries, holidays, and more</span>
                  </div>

                  <div class="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 shrink-0 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{color:`#A75D5D`}}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <span class="text-black">Recipients can book at their convenience</span>
                  </div>
                </div>
              </div>

              <div class="mt-auto flex flex-col gap-8">

                <a
                  href="#"
                  class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base"
                  style={{backgroundColor:`#A75D5D`}} >
                 Get Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      
       </div>
      <Footer/>
    </>
  )
}

export default Vip
