import React from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./short.css";
import {TabTitle} from "../../utils/GeneralFunctions";
import lsh from "../../assests/lsh.JPG";
import cnss from "../../assests/cnss.JPG";
import dc from "../../assests/dc.JPG";
import b from "../../assests/b.JPG";

const Short = () => {
  TabTitle("Short Term Courses - KamaStudio")

  return (
    <>
      <Navbar />
      <div className="services-header absolute-centre">Short-Term Courses</div>
      <div className="short-course-container max-width">
        {/* main-body */}
        <div class="container my-24 mx-auto md:px-6">
          <section class="mb-32">
            <div class="mb-16 flex flex-wrap">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src={lsh}
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 place-self-center ">
                <h1 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Long/Short Hairdressing
                </h1>

                <p class="mb-6 text-black dark:text-neutral-300 text-center" >
                Our Long Hairdressing Course is meticulously designed to provide you with a solid foundation in hairdressing techniques while focusing specifically on the intricacies of long hair styling and care.
                </p>
              </div>
            </div>

            <div class="mb-16 flex flex-wrap lg:flex-row-reverse">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src={b}
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6 place-self-center">
                <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">Barbering</h3>

                <p class=" text-black dark:text-neutral-300 text-center">
                Learn from skilled barbers, acquire versatile skills including cuts, shaves, and modern styles. Embrace creativity, refine client communication, and gain business insights.
                </p>
              </div>
            </div>

            <div class="flex flex-wrap">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src={cnss}
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 place-self-center">
                <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Coloring & Styling
                </h3>

                <p class="text-black dark:text-neutral-300 text-center ">
                Dive into the world of hair color, mastering classic and contemporary techniques guided by our experienced colorists. Gain insider knowledge on the latest products, tools, and trends to achieve stunning results.
                </p>
              </div>
            </div>
            <div class="mb-16 mt-16 flex flex-wrap lg:flex-row-reverse">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src={dc}
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6 place-self-center">
                <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Digital Course
                </h3>

                <p class="text-black dark:text-neutral-300 text-center">
                Master the art of virtual client consultations, ensuring exceptional service even in the digital realm. Develop skills to engage and connect with clients through digital platforms, building lasting relationships.
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* four-template */}

        <div className="template-body ">
          <div class="bg-white py-6 sm:py-8 lg:py-12">
            <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div class="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-2">
                <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6 ">
                  <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre ">
                    <svg
                      class="h-8 w-8 text-black"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <circle
                        cx="12"
                        cy="12"
                        r="9"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <polyline
                        points="12 7 12 12 15 15"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </h3>
                  <p
                    class="    mb-4 text-lg font-semibold absolute-centre"
                    style={{ color: `#A75D5D` }}
                  >
                    Duraion / Timings
                  </p>
                  <p className="absolute-centre text-black">
                    5 Days (Total 2 weeks)
                  </p>
                </div>

                <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6">
                  <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                    <svg
                      class="h-8 w-8 text-black"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path stroke="none" d="M0 0h24v24H0z" />{" "}
                      <path
                        d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <path
                        d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <line
                        x1="3"
                        y1="6"
                        x2="3"
                        y2="19"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <line
                        x1="12"
                        y1="6"
                        x2="12"
                        y2="19"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <line
                        x1="21"
                        y1="6"
                        x2="21"
                        y2="19"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </h3>
                  <p
                    class=" mb-4 text-lg font-semibold absolute-centre"
                    style={{ color: `#A75D5D` }}
                  >
                    Theory - Demo - Practical
                  </p>
                  <p className="absolute-centre  text-black">
                    Time : 9am - 12pm
                  </p>
                </div>

                <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6">
                  <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                    <svg
                      class="h-8 w-8 text-black"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <circle
                        cx="9"
                        cy="7"
                        r="4"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <path
                        d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <path
                        d="M16 3.13a4 4 0 0 1 0 7.75"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <path
                        d="M21 21v-2a4 4 0 0 0 -3 -3.85"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </h3>
                  <p
                    class="mb-4 text-lg font-semibold absolute-centre"
                    style={{ color: `#A75D5D` }}
                  >
                    Ratio
                  </p>
                  <p className="absolute-centre  text-black">
                    6 Students : 1 Teacher
                  </p>
                </div>

                <div class=" courses-box flex flex-col rounded-lg border p-4 md:p-6">
                  <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                    <svg
                      class="h-8 w-8 text-black"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <path
                        d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2"
                        style={{ color: `#A75D5D` }}
                      />{" "}
                      <rect
                        x="9"
                        y="3"
                        width="6"
                        height="4"
                        rx="2"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </h3>
                  <p
                    class="mb-4 text-lg font-semibold absolute-centre"
                    style={{ color: `#A75D5D` }}
                  >
                    Prerequisites
                  </p>
                  <p className="absolute-centre  text-black">
                    Basics of Cutting, styling & colouring techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* what we offering body */}
      <div
        className="what-we-learn-body"
        style={{ backgroundColor: `#FFF3EC` }}
      >
        <div class="container my-24 mx-auto md:px-6">
          <section class="mb-32 max-width">
            <div class="flex justify-center">
              <div class="max-w-[700px] text-center">
                <h2 class="about-h1 mb-20 my-10 text-3xl font-bold">
                  What is being provided?
                </h2>
              </div>
            </div>

            <div class="grid gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-12">
              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">0% EMI Facility</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">2 Week | 6 days a week</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">Products Included</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">Demo – Theory – Practical</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">
                      Training in 3 languages: Hindi, English, Marathi.
                    </p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">Course book included</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* what  you will learn */}
      <div
        className="what-we-learn-body"
       
      >
        <div class="container my-24 mx-auto md:px-6">
          <section class="mb-32 max-width">
            <div class="flex justify-center">
              <div class="max-w-[700px] text-center">
                <h2 class="about-h1 mb-20 my-10 text-3xl font-bold">
                  What will you learn?
                </h2>
              </div>
            </div>

            <div class="grid gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-12">
              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">Understand texture, Volume Placements and Securing Styles</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">  Session Styling</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">
Understand Hair Styling Products, Tools & Techniques</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">
Modern finishing & open hair styling techniques</p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">
                    Understand Hair Styling Products, Tools & Techniques
                    </p>
                  </div>
                </div>
              </div>

              <div class="mb-12">
                <div class="flex">
                  <div class="shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="mr-3 h-5 w-5 text-success"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        style={{ color: `#A75D5D` }}
                      />
                    </svg>
                  </div>
                  <div class="ml-2 grow">
                    <p class="mb-1 font-semibold">Interpretations Based on Current Trends and Requirements</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Short;
