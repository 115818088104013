
import Consultancy from "./pages/consultancy/consultancy";
import About from "./pages/about/about";
import Home from './pages/home/home';
import {Routes, Route} from 'react-router-dom';
import Others from "./pages/others/others";
import Models from "./pages/models-wanted/models";
import Contact from './pages/contact/contact';
import Services from "./pages/services/services";
import Vip from "./pages/vip/vip";
import Courses from "./pages/courses/courses";
import Short from "./pages/short-term/short";
import Long from "./pages/long-term/long";
import ScrollToTop from "./pages/scrollToTop";
import 'flowbite';


function App() {
  return (
    <>
     
      <Routes>
        <Route index element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/consultancy" element={<Consultancy />}/>
        <Route path="/others" element={<Others />}/>
        <Route path="/models-wanted" element={<Models />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/giftcard-membership" element={<Vip />}/>
        <Route path="/courses" element={<Courses />}/>
        <Route path="/short-term-courses" element={<Short />}/>
        <Route path="/long-term-courses" element={<Long />}/>
      </Routes>
      <ScrollToTop/>
    </>
  );
}

export default App;
