import React from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./about.css";
import {TabTitle} from "../../utils/GeneralFunctions";

const About = () => {
  TabTitle('About Us - KamaStudio');
  return (
    <>
      <Navbar />
      {/* heading block */}
      <div className="services-header absolute-centre">About Us</div>
      

      {/* Main container for about us */}
      <div className="aboutus-container ">
        <div class="container my-24 mx-auto md:px-6 max-width">
          <section class="mb-32">
            <div class="mb-16 flex flex-wrap">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://media.istockphoto.com/id/1325465927/photo/asian-student-studying-e-learning-online-education-concept-portrait-of-handsome-indian.webp?b=1&s=170667a&w=0&k=20&c=LNpUPG4s7rH68tTXNqEsrngv5_wjETMdWWvhMwzPHhU="
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6  place-self-center">
                <h1 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Men’s Barbering
                </h1>

                <p class="mb-6 text-black dark:text-neutral-300 text-justify">
                  Step into Anil's enchanting world of grooming mastery, where
                  three decades of unrivaled expertise have shaped timeless
                  trends and captured hearts. As a trusted mentor and skilled
                  artist, he leaves a lasting mark on his loyal clientele,
                  igniting their confidence with each meticulously crafted
                  haircut. Anil's passion for traditional barbering blazes
                  brightly, making him a living legend in the realm of men's
                  grooming, admired by both peers and patrons alike. Within the
                  walls of his barbershop, an atmosphere of warmth and
                  camaraderie envelops visitors, as tales are shared and
                  laughter resounds.
                </p>
              </div>
            </div>

            <div class="mb-16 flex flex-wrap lg:flex-row-reverse ">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://images.unsplash.com/photo-1530268729831-4b0b9e170218?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG1lbiUyMG9mZmljZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6 place-self-center">
                <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Cutting & Styling
                </h3>

                <p class="text-black dark:text-neutral-300 text-justify">
                  Devendra's salon is more than just a place for haircuts; it's
                  a haven of style and creativity. Clients eagerly step into his
                  world, knowing they'll emerge with hair that reflects their
                  unique personality and flair. With an eye for detail and a
                  commitment to staying ahead of trends, Devendra's reputation
                  as a trusted stylist continues to grow. Beyond the salon
                  chair, Devendra's influence extends to aspiring stylists, whom
                  he nurtures and mentors, igniting their passion for the craft.
                  His dedication to elevating the art of hair cutting and
                  styling is unrivaled, making him a true icon in the industry.
                 
                </p>
              </div>
            </div>

            <div class="flex flex-wrap">
              <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                <div
                  class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src="https://images.unsplash.com/photo-1537511446984-935f663eb1f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWVuJTIwb2ZmaWNlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                    class="w-full"
                    alt="Louvre"
                  />
                </div>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 place-self-center">
                <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">
                  Coloring Creativity
                </h3>

                <p class="text-black dark:text-neutral-300 text-justify ">
                  Introducing Ankit, the hair color wizard with over five years
                  of unparalleled expertise. With an artistic flair that knows
                  no bounds, he takes hair color to new heights, pushing
                  boundaries with each stroke of his brush. Ankit's passion for
                  creating personalized and stunning color transformations is
                  evident in every client he touches. Beyond his exceptional
                  talent, Ankit is a dedicated mentor and educator, sharing his
                  knowledge and skills with aspiring colorists. His commitment
                  to advancing the art of hair color is inspiring, making him a
                  revered figure in the industry. 
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* meet our team */}

        <div className="meet-container absolute-centre">
          {" "}
          <h2 class="about-h1 pb-20 py-20 text-3xl font-bold">Faces Behind Our Success</h2>
        </div>
        <div class="container my-24 mx-auto md:px-6">
          <section class="mb-32 text-center">
            <div class="lg:gap-xl-12 grid gap-x-6 md:grid-cols-3 xl:grid-cols-4">
              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/2.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />

                <p class="meet-font mb-2 font-bold">John Doe</p>
                <p class="text-black dark:text-neutral-300">Co-founder</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/5.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />

                <p class="meet-font mb-2 font-bold">Lisa Ferrol</p>
                <p class="text-black dark:text-neutral-300">Web designer</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/6.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Maria Smith</p>
                <p class="text-black dark:text-neutral-300">
                  Senior consultant
                </p>
              </div>
              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/7.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Agatha Bevos</p>
                <p class="text-black dark:text-neutral-300">Co-founder</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/8.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Darren Randolph</p>
                <p class="text-black dark:text-neutral-300">Marketing expert</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/9.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Soraya Letto</p>
                <p class="text-black dark:text-neutral-300">SEO expert</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/10.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Maliha Welch</p>
                <p class="text-black dark:text-neutral-300">Web designer</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/11.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Zeynep Dudley</p>
                <p class="text-black dark:text-neutral-300">Web developer</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/12.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Avaya Hills</p>
                <p class="text-black dark:text-neutral-300">Copywritter</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/13.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Thierry Fischer</p>
                <p class="text-black dark:text-neutral-300">
                  Senior consultant
                </p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/14.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Aisling Sheldon</p>
                <p class="text-black dark:text-neutral-300">Senior developer</p>
              </div>

              <div class="mb-12">
                <img
                  src="https://mdbcdn.b-cdn.net/img/new/avatars/15.jpg"
                  class="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20"
                  alt=""
                  style={{ maxWidth: `100px` }}
                />
                <p class="meet-font mb-2 font-bold">Ayat Black</p>
                <p class="text-black dark:text-neutral-300">Web designer</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
