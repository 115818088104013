import React from 'react'
import "./long.css"
import Navbar from '../../components/navbar/navbar'
import Footer from "../../components/footer/footer";
import {TabTitle} from "../../utils/GeneralFunctions";
import ln from "../../assests/ln.JPG";
import map from "../../assests/map.JPG";
import nb2a from "../../assests/nb2a.JPG";

const Long = () => {
  TabTitle("Long Term Courses - KamaStudio")
  return (
    <>
    <Navbar />
    <div className="services-header absolute-centre">Long-Term Courses</div>
    <div className="short-course-container max-width">
      {/* main-body */}
      <div class="container my-24 mx-auto md:px-6">
        <section class="mb-32">
          <div class="mb-16 flex flex-wrap">
            <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
              <div
                class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  src={nb2a}
                  class="w-full"
                  alt="Louvre"
                />
              </div>
            </div>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 place-self-center">
              <h1 class=" about-h1 mb-4 text-2xl font-bold text-center">
              Basic to Advance
              </h1>

              <p class="mb-6 text-black dark:text-neutral-300 text-center">
              Seamlessly transition through course levels, building upon your skills at a comfortable pace. Gain real-world experience through practical sessions, refining your abilities under expert guidance.
              </p>
              <p className='whitespace-pre-line text-center'>Equip yourself with the confidence and expertise to meet the demands of any hairstyling challenge.</p>
              
            </div>
          </div>

          <div class="mb-16 flex flex-wrap lg:flex-row-reverse">
            <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
              <div
                class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  src={ln}
                  class="w-full"
                  alt="Louvre"
                />
              </div>
            </div>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6 place-self-center">
              <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">Nails</h3>

              <p class="mb-6 text-black dark:text-neutral-300 text-center">
              Our expert instructors will guide you through hands-on practice and theoretical training, ensuring you acquire the skills and confidence to offer exceptional nail care services.
              </p>
             
              <p className='whitespace-pre-line text-center'>Learn the foundational skills for delivering relaxing and rejuvenating manicure and pedicure treatments.</p>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
              <div
                class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  src={map}
                  class="w-full"
                  alt="Louvre"
                />
              </div>
            </div>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 place-self-center">
              <h3 class=" about-h1 mb-4 text-2xl font-bold text-center">
                Makeup Pro
              </h3>

              <p class="mb-6 text-black dark:text-neutral-300 text-center">
              Collaborate with our experts to bring your ideas to life, resulting in personalized nail art that speaks volumes. Stay ahead of the curve with nail art that embraces the latest seasonal and fashion trends.
              </p>
              <span></span>
              <p className='whitespace-pre-line text-center'>Step into our world of nail artistry and experience a realm where creativity knows no bounds.</p>
            </div>
          </div>
        </section>
      </div>

      {/* four-template */}

      <div className="template-body ">
        <div class="bg-white py-6 sm:py-8 lg:py-12">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-2">
              <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6 ">
                <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre ">
                  <svg
                    class="h-8 w-8 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <polyline
                      points="12 7 12 12 15 15"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </h3>
                <p
                  class="    mb-4 text-lg font-semibold absolute-centre"
                  style={{ color: `#A75D5D` }}
                >
                  Duraion / Timings
                </p>
                <p className="absolute-centre text-black">
                12 Weeks ( 5 Days a Week )
                </p>
              </div>

              <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                  <svg
                    class="h-8 w-8 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <path
                      d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <path
                      d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <line
                      x1="3"
                      y1="6"
                      x2="3"
                      y2="19"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <line
                      x1="12"
                      y1="6"
                      x2="12"
                      y2="19"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <line
                      x1="21"
                      y1="6"
                      x2="21"
                      y2="19"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </h3>
                <p
                  class=" mb-4 text-lg font-semibold absolute-centre"
                  style={{ color: `#A75D5D` }}
                >
                  Theory - Demo - Practical
                </p>
                <p className="absolute-centre  text-black">
                  Time : 9am - 12pm ( Mon - Sat )
                </p>
              </div>

              <div class="courses-box flex flex-col rounded-lg border p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                  <svg
                    class="h-8 w-8 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <circle
                      cx="9"
                      cy="7"
                      r="4"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <path
                      d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <path
                      d="M16 3.13a4 4 0 0 1 0 7.75"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <path
                      d="M21 21v-2a4 4 0 0 0 -3 -3.85"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </h3>
                <p
                  class="mb-4 text-lg font-semibold absolute-centre"
                  style={{ color: `#A75D5D` }}
                >
                  Ratio
                </p>
                <p className="absolute-centre  text-black">
                  8 - 10 students
                </p>
              </div>

              <div class=" courses-box flex flex-col rounded-lg border p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl absolute-centre">
                  <svg
                    class="h-8 w-8 text-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <path
                      d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2"
                      style={{ color: `#A75D5D` }}
                    />{" "}
                    <rect
                      x="9"
                      y="3"
                      width="6"
                      height="4"
                      rx="2"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </h3>
                <p
                  class="mb-4 text-lg font-semibold absolute-centre"
                  style={{ color: `#A75D5D` }}
                >
                  Prerequisites
                </p>
                <p className="absolute-centre  text-black">
                Dedication to learn and develop through practice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* what we offering body */}
    <div
      className="what-we-learn-body"
      style={{ backgroundColor: `#FFF3EC` }}
    >
      <div class="container my-24 mx-auto md:px-6">
        <section class="mb-32 max-width">
          <div class="flex justify-center">
            <div class="max-w-[700px] text-center">
              <h2 class="about-h1 mb-20 my-10 text-3xl font-bold">
                What is being provided?
              </h2>
            </div>
          </div>

          <div class="grid gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-12">
            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">0% EMI Facility</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">100% Job Assistance</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Tool kit included & Products Included</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Demo – Theory – Practical</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">
                    Training in 3 languages: Hindi, English, Marathi.
                  </p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Course book included</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    {/* what  you will learn */}
    <div
      className="what-we-learn-body"
     
    >
      <div class="container my-24 mx-auto md:px-6">
        <section class="mb-32 max-width">
          <div class="flex justify-center">
            <div class="max-w-[700px] text-center">
              <h2 class="about-h1 mb-20 my-10 text-3xl font-bold">
                What will you learn?
              </h2>
            </div>
          </div>

          <div class="grid gap-x-6 md:grid-cols-2 lg:grid-cols-4 xl:gap-x-12">
            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">
Hair Science & Hair History</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">  Assessing Head Shape & Bone Structure</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">
                  Client Care and Consultation Technique</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">
                  Technical Theories & the Communication Process</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">
                  Lines, Layers, and Graduation
                  </p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Hair Cutting Techniques for Women and Men</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Digital Course</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Personal Growth</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Latest Trending Color Techniques</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Scissors & Clipper Over Comb</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Combining & Free-hand Techniques</p>
                </div>
              </div>
            </div>

            <div class="mb-12">
              <div class="flex">
                <div class="shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="mr-3 h-5 w-5 text-success"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{ color: `#A75D5D` }}
                    />
                  </svg>
                </div>
                <div class="ml-2 grow">
                  <p class="mb-1 font-semibold">Color Wheels & Charts and Formulas</p>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default Long
