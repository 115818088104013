import React from "react";
import "./services.css";
import "../../styles/commonClasses.css";
import "../../styles/variable.css";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import nails from "../../images/nails.jpeg";
import re from "../../assests/re.JPG";
import haircut from "../../assests/haircut.JPG";
import na from "../../assests/na.JPG";
import n from "../../assests/n.JPG";
import t from "../../assests/t.JPG";
import colors from "../../assests/colors.jpg";
import texture from "../../assests/texture.jpg";
import nails2 from "../../images/nails2.jpg";
import spa from "../../images/spa.jpeg";
import styling from "../../assests/styling.JPG";  
import hair from "../../images/hair.jpeg";
import makeup from "../../images/makeup.jpeg";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { TabTitle } from "../../utils/GeneralFunctions";

const Services = () => {
  TabTitle("Services - KamaStudio");
  return (
    <>
      <Navbar />
      <div className="services-container">
        {/* services-header */}
        <div className="services-header absolute-centre">Services</div>

        {/* services-mainbody */}
        <div className="services-body ">
          <div class="bg-white py-6 sm:py-8 lg:py-12 ">
            <div class="mx-auto max-w-screen-xl px-4 md:px-8 max-width">
              <div class="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
                <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                  <a
                    href="#"
                    class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                  >
                    <img
                      src={haircut}
                      loading="lazy"
                      alt="Photo by Minh Pham"
                      class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </a>

                  <div class="flex flex-col gap-2">
                    <h2 class="about-h1 text-xl font-bold text-gray-800">
                      <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                        Haircut
                      </span>
                    </h2>

                    <p class="text-gray-500" style={{ color: `black` , textAlignLast:`justify`}}>
                      Patch Haircut: ₹700*
                      <p>Layers & Texture: ₹800* </p>
                      <p >Bob: ₹1000* </p>
                      <p>Creative Cuts: ₹1100*</p>
                    
                    </p>
                  </div>
                </div>

                <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                  <a
                    href="#"
                    class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                  >
                    <img
                      src={styling}
                      loading="lazy"
                      alt="Photo by Lorenzo Herrera"
                      class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </a>

                  <div class="flex flex-col gap-2">
                    <h2 class="about-h1 text-xl font-bold text-gray-800">
                      <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                        Styling
                      </span>
                    </h2>

                    <p class="text-gray-500" style={{ color: `black`,textAlignLast:`justify` }}>
                      Blow Dry: ₹600*
                      <p>Ironing: ₹1200*</p>
                      <p>Hollywood Waves: ₹1200*</p>
                      <p>Hair Styling: ₹2000*</p>
                    
                    </p>
                  </div>
                </div>

                <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                  <a
                    href="#"
                    class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                  >
                    <img
                      src={colors}
                      loading="lazy"
                      alt="Photo by Magicle"
                      class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </a>

                  <div class="flex flex-col gap-2">
                    <h2 class="about-h1 text-xl font-bold text-gray-800">
                      <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                        Color
                      </span>
                    </h2>

                    <p class="text-gray-500" style={{ color: `black`, textAlignLast:`justify` }}>
                      Root Touch-up: ₹1200*
                      <p>Pre-lightner: ₹3500*</p>
                      <p>Global Color: ₹4000*</p>
                      <p> Balayage: ₹4500*</p>
                    </p>
                  </div>
                </div>

                <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                  <a
                    href="#"
                    class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                  >
                    <img
                      src={t}
                      loading="lazy"
                      alt="Photo by Martin Sanchez"
                      class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                    />
                  </a>

                  <div class="flex flex-col gap-2">
                    <h2 class="about-h1 text-xl font-bold text-gray-800">
                      <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                        Treatment
                      </span>
                    </h2>

                    <p class="text-gray-500" style={{ color: `black`,textAlignLast:`justify` }}>
                      HairSpa: ₹1000*
                      <p>Scalp soothing: ₹1200*</p>
                      <p> Olaplex: ₹2000*</p>
                      <p>Luxury ritual: ₹2000*</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white py-6 sm:py-8 lg:py-12">
              <div class="mx-auto max-w-screen-xl px-4 md:px-8">
                <div class="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
                  <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                    <a
                      href="#"
                      class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                    >
                      <img
                        src={texture}
                        loading="lazy"
                        alt="Photo by Minh Pham"
                        class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                      />
                    </a>

                    <div class="flex flex-col gap-2">
                      <h2 class="about-h1 text-xl font-bold text-gray-800">
                        <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                          Texture
                        </span>
                      </h2>

                      <p class="text-gray-500" style={{ color: `black`,textAlignLast:`justify`  }}>
                        Keratin: ₹3000*
                        <p>Cystine: ₹3500*</p>
                        <p>Botox: ₹4000*</p>
                        <p>QOD: ₹4000*</p>
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                    <a
                      href="#"
                      class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                    >
                      <img
                        src={n}
                        loading="lazy"
                        alt="Photo by Lorenzo Herrera"
                        class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                      />
                    </a>

                    <div class="flex flex-col gap-2">
                      <h2 class="about-h1 text-xl font-bold text-gray-800">
                        <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                          Nails
                        </span>
                      </h2>

                      <p class="text-gray-500" style={{ color: `black`,textAlignLast:`justify`  }}>
                        Basic: Hands: ₹500 & Feets: ₹600
                        <p>Regular: Hands: ₹800 & Feets: ₹900</p>
                        <p>Advance: Hands: ₹1200 & Feets: ₹1400</p>
                        <p>Luxury: Hands: ₹1500 & Feets: ₹2500</p>
                        <p></p>
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                    <a
                      href="#"
                      class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                    >
                      <img
                        src={re}
                        loading="lazy"
                        alt="Photo by Magicle"
                        class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                      />
                    </a>

                    <div class="flex flex-col gap-2">
                      <h2 class="about-h1 text-xl font-bold text-gray-800">
                        <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                          Reflexology
                        </span>
                      </h2>

                      <p class="text-gray-500" style={{ color: `black` ,textAlignLast:`justify`  }}>
                        Hand Massage: ₹400
                        <p>Half Leg Massage: ₹500</p>
                        <p> Back & Neck: ₹800</p>
                        <p>Full Leg Massage : ₹900</p>
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
                    <a
                      href="#"
                      class="group relative block h-56 w-full shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                    >
                      <img
                        src={na}
                        loading="lazy"
                        alt="Photo by Martin Sanchez"
                        class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                      />
                    </a>

                    <div class="flex flex-col gap-2">
                      <h2 class="about-h1 text-xl font-bold text-gray-800">
                        <span style={{ color: `#A75D5D`, fontStyle: `normal` }}>
                          Nail Art
                        </span>
                      </h2>

                      <p class="text-gray-500" style={{ color: `black`,textAlignLast:`justify` }}>
                        Gel Polish: ₹800
                        <p>Gel Polish Removal: ₹500</p>
                        <p>Regular Polish: ₹200</p>
                        <p>Cut & Filling: ₹200</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* table content */}

            <div className="meet-container absolute-centre">
              {" "}
              <h2 class="about-h1 pb-20 py-20 text-3xl font-bold">
                MakeUp For Bride
              </h2>
            </div>
            <div class="relative overflow-x-auto max-width ">
              <table class="mt-20 w-full text-m text-left text-gray-500 dark:text-gray-400 ">
                <thead
                  class="text-xl  uppercase "
                  style={{ backgroundColor: `#A75D5D` }}
                >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 "
                      style={{ color: `#FFF3EC` }}
                    >
                      Makeup (In-Salon)
                      <p className="text-xs">(Party/Roka/Mehendi/Engagement)</p>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3"
                      style={{ color: `#FFF3EC` }}
                    >
                      Senior Artist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 "
                      style={{ color: `#FFF3EC` }}
                    >
                      Creative Artist
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      Regular
                    </th>
                    <td class="px-6 py-4">₹8500</td>
                    <td class="px-6 py-4">₹10500</td>
                  </tr>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      HD Makeup
                    </th>
                    <td class="px-6 py-4">₹12500</td>
                    <td class="px-6 py-4">₹15000</td>
                  </tr>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      Airbrush Makeup
                    </th>
                    <td class="px-6 py-4">₹16500</td>
                    <td class="px-6 py-4">₹20000</td>
                  </tr>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      Bride Maids Makeup
                    </th>
                    <td class="px-6 py-4">₹3500</td>
                    <td class="px-6 py-4">₹5000</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="meet-container absolute-centre">
              {" "}
              <h2 class="about-h1 pb-20 py-20 text-3xl font-bold">
                Exclusive Packages
              </h2>
            </div>

            <div class="relative overflow-x-auto  max-width ">
              <table class="mt-20 w-full text-m text-left text-gray-500 dark:text-gray-400 ">
                <thead
                  class="text-xl  uppercase "
                  style={{ backgroundColor: `#A75D5D` }}
                >
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 "
                      style={{ color: `#FFF3EC` }}
                    >
                      Groom & Bride Packages
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 "
                      style={{ color: `#FFF3EC` }}
                    >
                      Regular Package
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3"
                      style={{ color: `#FFF3EC` }}
                    >
                      Premium Package
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      Bride
                    </th>
                    <td class="px-6 py-4">₹7000*</td>
                    <td class="px-6 py-4">₹19000*</td>
                  </tr>
                  <tr style={{ color: `black` }}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      Groom
                    </th>
                    <td class="px-6 py-4">₹6000*</td>
                    <td class="px-6 py-4">₹14000*</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <span
              className=" text-services max-width  text-left "
              style={{ fontWeight: `600` }}
            >
              *GST Not Applicable, T&C Applied.<span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span> </span>
              <span></span> *You can Customize according to your requirement.
            </span>
          </div>
          <div className="button-services max-width absolute-centre  ">
            <button
              type="button"
              class=" max-width home-button1 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              {" "}
              <a
                className="appoinment-call-btn"
                href="tel:0712-4069279"
                style={{
                  display: `inline-flex`,
                  textDecorationLine: `none`,
                  alignItems: `center`,
                }}
              >
                <svg
                  class="h-6 w-6 mr-2 text-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <path
                    d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <path d="M15 7a2 2 0 0 1 2 2" style={{ color: `#FFF3EC` }} />{" "}
                  <path d="M15 3a6 6 0 0 1 6 6" style={{ color: `#FFF3EC` }} />
                </svg>
                Call Us Now
              </a>
            </button>

            <button
              type="button"
              class=" max-width home-button1 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <a
                className="appoinment-call-btn"
                href="https://wa.me/918600012358"
                style={{
                  display: `inline-flex`,
                  textDecorationLine: `none`,
                  alignItems: `center`,
                }}
              >
                <svg
                  class="h-6 w-6 mr-2 text-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <path
                    d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <line
                    x1="12"
                    y1="12"
                    x2="12"
                    y2="12.01"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <line
                    x1="8"
                    y1="12"
                    x2="8"
                    y2="12.01"
                    style={{ color: `#FFF3EC` }}
                  />{" "}
                  <line
                    x1="16"
                    y1="12"
                    x2="16"
                    y2="12.01"
                    style={{ color: `#FFF3EC` }}
                  />
                </svg>
                MESSAGE TO BOOK
              </a>
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Services;
