import React from "react";
import "./others.css";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunctions";
import coursesimg from "../../assests/coursesimg.JPG";
import mw from "../../assests/mw.JPG";
import gf from "../../assests/gf.JPG";
import mm from "../../assests/mm.JPG";

const Others = () => {
  TabTitle("Others - KamaStudio");

  return (
    <>
      <Navbar />
      {/* heading block */}
      <div className="services-header absolute-centre">Others</div>

      {/* Hero section */}

      <div className="hero-section max-width">
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div className="mb-6 flex items-end justify-between gap-4"></div>

            <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
              <div>
                <Link
                  to={"/courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <img
                    src={coursesimg}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <a
                    href="#"
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Courses
                  </a>
                </div>
              </div>

              <div>
                <Link
                  to={"/models-wanted"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1500"
                >
                  <img
                    src={mw}
                    loading="lazy"
                    alt="Photo by engin akyurt"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <Link
                    to={"/models-wanted"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Models Wanted
                  </Link>
                </div>
              </div>

              <div>
                <Link
                  to={"/giftcard-membership"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="2000"
                >
                  <img
                    src={gf}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <Link
                    to={"/giftcard-membership"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Gift Card
                  </Link>
                </div>
              </div>

              <div>
                <Link
                  to={"/giftcard-membership"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3 absolute-centre"
                  data-aos="flip-left"
                  data-aos-duration="2500"
                >
                  <img
                    src={mm}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col ">
                  <a
                    href="#"
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre "
                  >
                    Membership
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ Section */}

        <div class="bg-white py-6 sm:py-8 lg:py-12">
          <div class="mx-auto max-w-screen-xl px-4 md:px-8">
            <div class="mb-10 md:mb-16">
              <h2 class=" about-h1 mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                Frequently asked questions
              </h2>
            </div>

            <div class="grid gap-4 sm:grid-cols-2 md:gap-8">
              <div
                class="rounded-lg bg-gray-100 p-5 "
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ backgroundColor: `#FFF8F5` }}
              >
                <div class="mb-4 flex items-center justify-between gap-4 border-b pb-4">
                  <h3
                    class="Faq-h2 font-semibold text-yellow-600 sm:text-lg md:text-xl"
                    style={{ color: `#A75D5D` }}
                  >
                    Is it possible to get assistance as I progress through my
                    Basic - Advance course?
                  </h3>
                </div>

                <p class="text-black ">
                  The curriculum includes salon experience, leading to many
                  students securing permanent positions afterward.
                </p>
              </div>

              <div
                class="rounded-lg bg-gray-100 p-5"
                data-aos="fade-up"
                data-aos-duration="1500"
                style={{ backgroundColor: `#FFF8F5` }}
              >
                <div class="mb-4 flex items-center justify-between gap-4 border-b pb-4">
                  <h3
                    class="Faq-h2 font-semibold  text-yellow-600 sm:text-lg md:text-xl"
                    style={{ color: `#A75D5D` }}
                  >
                    Is it necessary for me to bring my personal kit?
                  </h3>
                </div>

                <p class="text-black">
                  Newcomers to the program will receive a comprehensive
                  hairdressing kit.
                </p>
              </div>

              <div
                class="rounded-lg bg-gray-100 p-5"
                data-aos="fade-up"
                data-aos-duration="2000"
                style={{ backgroundColor: `#FFF8F5` }}
              >
                <div class="mb-4 flex items-center justify-between gap-4 border-b pb-4">
                  <h3
                    class="Faq-h2 font-semibold  text-yellow-600 sm:text-lg md:text-xl "
                    style={{ color: `#A75D5D` }}
                  >
                    Is it possible to enroll in the course on a part-time
                    basis?"
                  </h3>
                </div>

                <p class="text-black">
                  We provide adaptable scheduling options based on the start
                  dates of the structured courses.
                </p>
              </div>

              <div
                class="rounded-lg bg-gray-100 p-5"
                data-aos="fade-up"
                data-aos-duration="2500"
                style={{ backgroundColor: `#FFF8F5` }}
              >
                <div class="mb-4 flex items-center justify-between gap-4 border-b pb-4">
                  <h3
                    class="Faq-h2 font-semibold  text-yellow-600 sm:text-lg md:text-xl"
                    style={{ color: `#A75D5D` }}
                  >
                    Does my age matter?
                  </h3>
                </div>

                <p class="text-black">
                  No, we have an inclusive policy, welcoming students aged 18
                  and above, regardless of their experience level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Others;
