import React from 'react'
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import { Link } from "react-router-dom";
import {TabTitle} from "../../utils/GeneralFunctions";
import texture from "../../assests/texture.jpg";
import cns from "../../assests/cns.JPG";
import n1 from "../../assests/n1.JPG";
import mp from "../../assests/mp.JPG";
import b2a from "../../assests/b2a.JPG";
import bb from "../../assests/bb.JPG";
import dcs from "../../assests/dcs.JPG";

const Courses = () => {
  TabTitle("Courses - KamaStudio")

  return (
    <>
    <Navbar/>
    <div className="services-header absolute-centre">Courses</div>
    {/* courses-body-long */}
    <div className="hero-section max-width">
        <h1 className='about-h1 absolute-centre max-width'>Short Term Courses</h1>
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div className="mb-6 flex items-end justify-between gap-4"></div>

            <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
              <div>
                <Link
                  to={"/short-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <img
                    src={texture}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <Link
                    to={'/short-term-courses'}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Long/Short Hairdressing
                  </Link>
                </div>
              </div>

              <div>
                <Link
                  to={"/short-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1500"
                >
                  <img
                    src={bb}
                    loading="lazy"
                    alt="Photo by engin akyurt"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <Link
                    to={"/short-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Barbering
                  </Link>
                </div>
              </div>

              <div>
                <Link
                  to={"/short-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="2000"
                >
                  <img
                    src={cns}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                  <Link
                    to={"/short-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Coloring & Styling
                  </Link>
                </div>
              </div>

              <div>
              <Link
                    to={"/short-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3 absolute-centre"
                  data-aos="flip-left"
                  data-aos-duration="2500"
                >
                  <img
                    src={dcs}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col ">
                <Link
                    to={"/short-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre "
                  >
                    Digital Course
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
{/* courses-body-short */}
      <div className="hero-section max-width">
        <h1 className='about-h1 absolute-centre max-width'>Long Term Courses</h1>
        <div className="bg-white py-6 sm:py-8 lg:py-12 ">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8 ">
            <div className="mb-6 flex items-end justify-between gap-4 "></div>

            <div className="absolute-centre grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
              <div>
                <Link
                  to={"/long-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <img
                    src={b2a}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                <Link
                  to={"/long-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Basics to Advance
                  </Link>
                </div>
              </div>

              <div>
              <Link
                  to={"/long-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="1500"
                >
                  <img
                    src={n1}
                    loading="lazy"
                    alt="Photo by engin akyurt"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                <Link
                  to={"/long-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Nails
                  </Link>
                </div>
              </div>

              <div>
              <Link
                  to={"/long-term-courses"}
                  className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
                  data-aos="flip-left"
                  data-aos-duration="2000"
                >
                  <img
                    src={mp}
                    loading="lazy"
                    alt="Photo by Austin Wade"
                    className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </Link>

                <div className="Faq-h2 flex flex-col">
                <Link
                  to={"/long-term-courses"}
                    className="text-footer text-lg font-bold text-gray-800 transition duration-100  lg:text-xl absolute-centre"
                  >
                    Makeup Pro
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Courses
