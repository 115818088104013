import React from "react";
import "./footer.css";
import logo from "../../assests/KAMA_WHITELOGO.jpg";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa";
import { Link } from "react-router-dom";
import footerlogo from "../../assests/footerlogo2.png";

const Footer = () => {
  return (
    <div class="bg-black">
      <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="mb-10 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
          <div class="col-span-full lg:col-span-3">
            <div className="img-footer ">
              <Link to={"/home"}>
                <img className="logofoot cur-po" src={footerlogo} alt="" />
              </Link>
            </div>

            <p class=" logo-address inline-flex  items-centre gap-3 mb-6 text-gray-100 sm:pr-8 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class=" h-8 w-8 absolute-center"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>

              <p>
                274/2, Central Bazar Road, below sakal media pvt ltd, New
                Ramdaspeth, Nagpur, Maharashtra 440010
              </p>
            </p>

            <div class="footer-icon flex gap-5">
              <a
                href="https://instagram.com/kama.studiongp?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                class="text-gray-100 transition duration-100 hover:text-gray-300 active:text-gray-600"
              >
                <svg
                  class="text-footer ml-1 h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/kama.studiongp?mibextid=LQQJ4d"
                target="_blank"
                class="text-footer text-gray-100 transition duration-100 hover:text-gray-300 active:text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                </svg>
              </a>
              <a
                href="https://maps.app.goo.gl/5dkXePumy4FJpdMu8"
                target="_blank"
                class="text-footer text-gray-100 transition duration-100 hover:text-gray-300 active:text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div>
            <div class="mb-4 mt-20 font-bold uppercase tracking-widest text-gray-100">
              About Us
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <Link
                  to={"/courses"}
                  href="#"
                  class=" text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Courses
                </Link>
              </div>

              <div>
                <Link
                  to={"/services"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Services
                </Link>
              </div>

              <div>
                <Link
                  to={"/consultancy"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Consultancy
                </Link>
              </div>
            </nav>
          </div>

          <div>
            <div class="mb-4  mt-20 font-bold uppercase tracking-widest text-gray-100">
              Contact Us
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <Link
                  to={"/about"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  About
                </Link>
              </div>

              <div>
                <a
                  href="tel:0712-4069279"
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Book An Appointment
                </a>
              </div>

              <div>
                <Link
                  to={"/models-wanted"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Models Wanted
                </Link>
              </div>
            </nav>
          </div>

          <div>
            <div class="mb-4  mt-20 font-bold uppercase tracking-widest text-gray-100">
              Others
            </div>

            <nav class="flex flex-col gap-4">
              <div>
                <Link
                  to={"/giftcard-membership"}
                  target="_self"
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Gift Card
                </Link>
              </div>

              <div>
                <Link
                  to={"/giftcard-membership"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Membership
                </Link>
              </div>

              <div>
                <Link
                  to={"/others"}
                  class="text-footer text-gray-100 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  FAQ
                </Link>
              </div>
            </nav>
          </div>
        </div>

        <div class="border-t border-gray-800 py-8 text-center text-sm text-gray-100">
          Copyright © 2023 - Presents KamaStudio. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
