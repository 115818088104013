import React from "react";
import Heading from "../../components/pageheading/heading";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./consultancy.css";
import { BsPeopleFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import BearDburys from "../../images/BearDburys-logo.png";
import Cheryl from "../../images/Cheryl-s-Cosmeceuticals-Logo-modified.png";
import ikonic from "../../images/ikonic-logo.png";
import Loreal from "../../images/Logo-Loreal-Professional-Paris.png";
import marc from "../../images/marc-logo.png";
import nashi from "../../images/nashi-argan-logo.png";
import O3 from "../../images/O3-logo.png";
import makeupstudio from "../../images/make-up-studio-logo.png";
import qod from "../../images/qod-logo.png";
import schwa from "../../images/Schwarzkopf_Professional-logo.png";
import rika from "../../images/rica-logo.png";
import shea from "../../images/shea-logo.png";
import skeyndor from "../../images/skeyndor-logo.png";
import vedic from "../../images/vedic-valley-logo.png";
import wahl from "../../images/wahl-pro-logo.png";
import wella from "../../images/wella-logo.png";
import {TabTitle} from "../../utils/GeneralFunctions";

const Consultancy = () => {
  TabTitle('Consultancy - KamaStudio');

  return (
    <>
      <Navbar />
      {/* Header-context */}
      <div className="services-header absolute-centre">Consultancy</div>
      {/* Body */}
      <div className="consultancy-container max-width">
        <div className="rigth-section ">
          <h2 className="about-h1">
            Ignite Your Salon Success: Unveiling the Key to Explosive Expansion!
          </h2>
          <p>
            Welcome to the gateway of salon greatness! Our Consultancy Program
            is your ticket to transforming your salon's success. From Salon
            Chains to individual artists, our tailored courses suit all,
            covering every aspect of Hairdressing & Makeup. With our seasoned
            trainers guiding your path, watch your skills soar and your salon
            thrive. Embrace the brilliance of your creativity and take your
            expertise to new heights. Step into a world of possibilities as you
            explore exciting specialties: master advanced haircuts that leave
            clients in awe, create stunning hair colors that define trends,
            weave magic with flawless bridal makeup, and embrace the art of
            innovative nail designs. Seize this opportunity to elevate your
            craft and set your salon apart from the rest. The journey to
            excellence starts here. Unlock the door to a brighter, more
            prosperous future in the world of beauty.
          </p>
          <h2 className="about-h1">Consulting Overview</h2>
          <div className="right-icons">
            <BsFillCheckCircleFill className="tick-icon" />{" "}
            <span>Inspirational Creative Seminars</span>
            <BsFillCheckCircleFill className="tick-icon" />{" "}
            <span>Innovative Techniques</span>
            <BsFillCheckCircleFill className="tick-icon" />{" "}
            <span>Business Development</span>
          </div>
        </div>
        <div className="left-section">
       
          <img
            src="https://images.unsplash.com/photo-1581970196594-f0a8ad70eaa1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTJ8fGxlY3R1cmV8ZW58MHwxfDJ8fHww&auto=format&fit=crop&w=500&q=60"
            className=" left-img h-auto  rounded-lg  shadow-lg dark:shadow-black/10  max-width"
            alt="..."
          />
        </div>
      </div>

      {/* our brand partner container */}
      <div className="brand-logo-container">
        <h2 className="brand-heading absolute-centre">Our Brand Partners</h2>
        <div className="brand-logos">
          <div className="brand-logos-slide ">
            <img src={BearDburys} alt="logo" />
            <img src={makeupstudio} alt="logo" />
            <img src={rika} alt="logo" />
            <img src={Cheryl} alt="logo" />
            <img src={Loreal} alt="logo" />
            <img src={wella} alt="logo" />
            <img src={vedic} alt="logo" />
            <img src={qod} alt="logo" />
            <img src={nashi} alt="logo" />
            <img src={skeyndor} alt="logo" />
            <img src={ikonic} alt="logo" />
            <img src={wahl} alt="logo" />
            <img src={shea} alt="logo" />
            <img src={O3} alt="logo" />
            <img src={marc} alt="logo" />
            <img src={schwa} alt="logo" />
            <img src={BearDburys} alt="logo" />
            <img src={makeupstudio} alt="logo" />
            <img src={rika} alt="logo" />
            <img src={Cheryl} alt="logo" />
            <img src={Loreal} alt="logo" />
            <img src={wella} alt="logo" />
            <img src={vedic} alt="logo" />
            <img src={qod} alt="logo" />
            <img src={nashi} alt="logo" />
            <img src={skeyndor} alt="logo" />
            <img src={ikonic} alt="logo" />
            <img src={wahl} alt="logo" />
            <img src={shea} alt="logo" />
            <img src={O3} alt="logo" />
            <img src={marc} alt="logo" />
            <img src={schwa} alt="logo" />
            <img src={BearDburys} alt="logo" />
            <img src={makeupstudio} alt="logo" />
            <img src={rika} alt="logo" />
            <img src={Cheryl} alt="logo" />
            <img src={Loreal} alt="logo" />
            <img src={wella} alt="logo" />
            <img src={vedic} alt="logo" />
            <img src={qod} alt="logo" />
            <img src={nashi} alt="logo" />
            <img src={skeyndor} alt="logo" />
            <img src={ikonic} alt="logo" />
            <img src={wahl} alt="logo" />
            <img src={shea} alt="logo" />
            <img src={O3} alt="logo" />
            <img src={marc} alt="logo" />
            <img src={schwa} alt="logo" />
          </div>
        </div>
      </div>

      {/* appointmenet container */}
      <div className="appointment-div">
        <div className="appointment-icon absolute-centre">
          <BsPeopleFill size={`2.5em`} />
        </div>
        <h2 className="about-h1 appoinment-text absolute-centre" >
          Ask for your 1:1 Consultation
        </h2>
        <div className="appoinment-button absolute-centre">
        <button
              type="button"
              class=" max-width home-button1 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            ><a className="appoinment-call-btn"href="tel:0712-4069279" style={{display:`inline-flex`,textDecorationLine:`none`,alignItems:`center`}}> <svg class="h-6 w-6 mr-2 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"style={{color:`#FFF3EC`}}/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" style={{color:`#FFF3EC`}}/>  <path d="M15 7a2 2 0 0 1 2 2" style={{color:`#FFF3EC`}}/>  <path d="M15 3a6 6 0 0 1 6 6" style={{color:`#FFF3EC`}}/></svg>
              Book an Appointment
              </a>
            </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Consultancy;
